import request from '@/util/request';

/**
 * 版权交易法律资讯律所 接口
 * @Author Hubx
 */

// 免登录 获取详情
export function legalLawFirmsInfo(id) {
  return request({
    url: `/tran/TranLegalLawFirms/ua/` + id,
    method: 'get'
  });
}

// 免登录 获取列表
export function legalLawFirmsList(params) {
  return request({
    url: `/tran/TranLegalLawFirms/ua/list`,
    method: 'get',
    params: params
  });
}

// 免登录 保存记录
export function legalLawFirmsSave(data) {
  return request({
    url: `/tran/TranLegalLawFirms/ua/saveOrUpdate`,
    method: 'post',
    data: data
  });
}
