<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="-1"></top>
      <div class="container-box copyrightRegistration width-100 flex-row justify-content-spaceBetween align-items-star">
        <div class="width-33 left">
          <p class="fs-mid-big fw-mid black border-b">常见法律问题</p>
          <el-menu default-active="0" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
            <el-submenu :index="index" v-for="(item, index) in questionList" :key="index">
              <template slot="title">
                <img src="../assets/image/05.png" class="icon-question" alt="" />
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item class="width-100 flex-row justify-content-spaceBetween align-items-star">
                  <img src="../assets/image/04.png" class="icon-question" alt="" />
                  <span>{{ item.replyContent }}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
        <div class="width-65 right">
          <p class="fs-super-big fw-mid black border-b">法律咨询</p>
          <div class="width-100 padding-Tb-20">
            <p class="width-100 fs-big theme-blue">{{ lawFirms.name }}</p>
            <p class="desc fs-mid theme-blue">
              {{ lawFirms.serviceScope }}
            </p>
          </div>
          <div class="width-100 form-box">
            <el-form ref="form" :model="form" :rules="rules">
              <el-form-item label="问题标题" prop="title">
                <el-input v-model="form.title" placeholder="请输入问题标题"></el-input>
              </el-form-item>
              <el-form-item label="问题详细" prop="content">
                <el-input type="textarea" v-model="form.content" :autosize="{ minRows: 10, maxRows: 15 }" placeholder="请详细描述问题"></el-input>
              </el-form-item>
              <el-form-item label="图片资料">
                <div class="width-85 flex-row justify-content-start align-items-star">
                  <el-upload
                    class="avatar-uploader"
                    :action="uploadImgUrl"
                    :headers="headers"
                    :show-file-list="false"
                    :on-success="handleImageSuccess"
                    :before-upload="beforeImageUpload"
                  >
                    <img v-if="form.image" :src="imagePrefix + form.image" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>
              <el-form-item class="width-100 textAlign-c">
                <el-button type="primary" class="width-30" @click="onSubmit">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { legalLawFirmsInfo } from '@/api/TranLegalLawFirms';
import { legalQuestionList, legalQuestionSave } from '@/api/TranLegalQuestion';

export default {
  name: 'legalAdviceQuestions',
  components: {
    top,
    bottom
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_SERVE_PATH + '/common/upload', // 上传的图片服务器地址
      imagePrefix: process.env.VUE_APP_IMAGE_PREFIX,
      uploadProfile: {
        fileSize: 2, // 文件最大尺寸
        fileType: ['jpg', 'png'] // 文件格式限制
      },
      headers: {
        Authorization: localStorage.getItem('bqsb_login')
      },
      lawFirmsId: null,
      lawFirms: {},
      questionList: [],
      form: {
        title: '', //标题
        content: '', //详细
        image: '' //图片
      },
      rules: {
        title: [{ required: true, message: '请输入问题标题', trigger: 'blur' }],
        content: [
          {
            required: true,
            message: '请详细描述问题',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  created() {
    this.lawFirmsId = this.$route.query.id;
    this.form.lawFirmsId = this.$route.query.id;
    legalLawFirmsInfo(this.lawFirmsId).then((res) => {
      this.lawFirms = res.data;
    });
    // 常见法律问题列表
    legalQuestionList({ usualTag: '1' }).then((res) => {
      this.questionList = res.rows;
    });
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    async onSubmit() {
      console.log('submit!');
      const dataForm = this.$refs.form;
      await dataForm.validate().then(() => {
        legalQuestionSave(this.form).then((res) => {
          if (res.code === 200) {
            this.$message.success('提交成功！');
          }
          this.$router.go(-1);
        });
      });
    },
    beforeImageUpload(file) {
      return new Promise((resolve, reject) => {
        let isImg = false;
        console.log(file);
        if (this.uploadProfile.fileType.length) {
          let fileExtension = '';
          if (file.name.lastIndexOf('.') > -1) {
            fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
          }
          isImg = this.uploadProfile.fileType.some((type) => {
            if (file.type.indexOf(type) > -1) return true;
            if (fileExtension && fileExtension.indexOf(type) > -1) return true;
            return false;
          });
        } else {
          isImg = file.type.indexOf('image') > -1;
        }

        if (!isImg) {
          this.$message.error(`文件格式不正确, 请上传${this.uploadProfile.fileType.join('/')}图片格式文件!`);
          return reject(false);
        }
        if (file.name.lastIndexOf(',') > -1) {
          this.$message.error(`文件名格式不正确, 请确保文件名内不出现’,‘符号!`);
          return reject(false);
        }
        if (this.uploadProfile.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.uploadProfile.fileSize;
          if (!isLt) {
            this.$message.error(`上传图片大小不能超过 ${this.uploadProfile.fileSize} MB!`);
            return reject(false);
          }
        }
        this.loading = this.$loading({
          lock: true,
          text: '上传中',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        return resolve(file);
      });
    },
    handleImageSuccess(res) {
      this.loading.close();
      this.form.image = res.fileName;
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0;
    .left {
      border-radius: 20px;
      padding: 30px 30px;
      background: white;
      /deep/.el-submenu {
        margin-bottom: 10px !important;
      }
      /deep/.el-submenu__title {
        padding: 0px 0 !important;
        margin: 10px 0;
        width: 100%;
        span {
          font-size: 20px;
          font-weight: bold;
          width: 80%;
          display: inline-block;
          white-space: pre-wrap;
          line-height: 24px;
        }
      }
      /deep/.el-menu {
        border-right: 0;
        .el-submenu__icon-arrow {
          font-size: 18px;
        }
        .el-menu-item {
          padding: 0 !important;
          height: auto;

          span {
            white-space: pre-wrap;
            line-height: 24px;
            font-size: 18px;
          }
        }
        .el-menu-item-group__title {
          padding: 0;
        }
      }
      .icon-question {
        width: 24px;
        margin-right: 10px;
      }
    }
    .right {
      border-radius: 20px;
      padding: 30px 30px;
      background: white;
      .desc {
        margin-top: 20px;
        color: #83b6ff;
        padding: 20px;
        background: #f3f9ff;
        line-height: 2rem;
      }

      .form-box {
        margin-top: 40px;
        padding: 0 3%;
        /deep/.el-form-item {
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item__label,
.el-input__inner,
.el-radio__label,
.el-textarea__inner,
.el-form-item__error {
  font-size: 18px;
}
.copyrightRegistration .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  height: 3rem;
}
.copyrightRegistration .el-form-item {
  margin-bottom: 60px;
}

.copyrightRegistration .el-form-item__content {
  margin-left: 150px !important;
}
.copyrightRegistration .el-select {
  width: 100%;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio {
  padding-left: 0 !important;
  padding-right: 25px !important;
  margin-right: 10px !important;
}

.copyrightRegistration .el-button--primary {
  padding: 20px 80px;
}
.copyrightRegistration .el-button--primary span {
  font-size: 20px;
}
.copyrightRegistration .el-menu-item:hover,
.copyrightRegistration .el-submenu__title:hover {
  background: rgba(0, 0, 0, 0);
}
</style>
