import request from '@/util/request';

/**
 * 版权交易法律咨询提问 接口
 * @Author Hubx
 */

// 免登录 获取详情
export function legalQuestionInfo(id) {
  return request({
    url: `/tran/TranLegalQuestion/ua/` + id,
    method: 'get'
  });
}

// 免登录 获取列表
export function legalQuestionList(params) {
  return request({
    url: `/tran/TranLegalQuestion/ua/list`,
    method: 'get',
    params: params
  });
}

// 免登录 获取列表 by user
export function legalQuestionListByUser(params) {
  return request({
    url: `/tran/TranLegalQuestion/ua/list/user`,
    method: 'get',
    params: params
  });
}

// 免登录 保存记录
export function legalQuestionSave(data) {
  return request({
    url: `/tran/TranLegalQuestion/ua/saveOrUpdate`,
    method: 'post',
    data: data
  });
}
